/*
  Style for Homepage
*/

.pagination {
  color: var(--btn-paginator-text-color);
  font-family: 'Lato', sans-serif;

  a:hover {
    text-decoration: none;
  }

  .page-item {
    .page-link {
      color: inherit;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      border-radius: 50%;
      border: 1px solid var(--btn-paginator-border-color);
      background-color: var(--button-bg);

      &:hover {
        background-color: var(--btn-paginator-hover-color);
      }
    }

    &.active {
      .page-link {
        background-color: var(--btn-paginator-hover-color);
        color: var(--btn-text-color);
      }
    }

    &.disabled {
      cursor: not-allowed;

      .page-link {
        color: rgba(108, 117, 125, 0.57);
        border-color: var(--btn-paginator-border-color);
        background-color: var(--button-bg);
      }
    }

    &:first-child .page-link,
    &:last-child .page-link {
      border-radius: 50%;
    }
  } // .page-item

} // .pagination

#post-list {
  h2 {
    color: var(--heading-color);
    font-size: 3.75rem;
    @media (min-width: 850px) {
      font-size: 4.75rem;
    }
    @media (min-width: 960px) {
      font-size: 5.75rem;
    }
    font-weight: 900;
    line-height: 1.05;
  }
  margin-top: 1rem;
  padding-right: 0.5rem;

  .post-preview {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--main-border-color);

    a {
      color: var(--link-color);
      transition: color .2s, border-color .2s;
      border-bottom: 1px solid transparent;

      &:hover {
        text-decoration: none;
        border-bottom-color: var(--main-border-color);
      }
    }

    h3 {
      font-size: 1.8rem;
      margin: 0;
    }

    .post-meta {
      i {
        font-size: 0.73rem;

        &:not(:first-child) {
          margin-left: 1.2rem;
        }
      }

      em {
        @extend %normal-font-style;
      }
    }

    .post-content {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      color: var(--post-list-text-color);

      > p {
        /* Make preview shorter on the homepage */
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .post-footer {
      margin: .25rem 0 1rem;

      a {
        color: var(--link-color);
        text-decoration: none;
        transition: color .25s, border-color .25s;
        border-bottom: 1px solid transparent;
        font-weight: 700;
        font-size: 90%;
        display: inline-block;
        padding: .1em 0;

        .icon {
          margin-left: .25em;
          font-size: 90%;
          transition: transform .2s;
          display: inline-block;
          transform: translateX(-1px);
        }

        &:hover {
          text-decoration: none;
          border-bottom-color: var(--main-border-color);

          .icon {
            transform: translateX(3px);
          }
        }
      }
    }

    .pin {
      > i {
        transform: rotate(45deg);
        padding-left: 3px;
        color: var(--pin-color);
      }

      > span {
        display: none;
      }
    }

  } // .post-preview
} // #post-list

.post-list-footer {
  margin-top: 2rem;
  text-align: center;

  a {
    color: var(--link-color);
    font-size: 120%;
    text-decoration: none;
    transition: color .25s, border-color .25s;
    border-bottom: 1px solid var(--main-border-color);
    display: inline-block;
    padding: .15em 0;
    font-weight: 700;
    position: relative;
  }
}

/* Hide SideBar and TOC */
@media all and (max-width: 830px) {
  .pagination {
    justify-content: space-evenly;

    .page-item {
      &:not(:first-child):not(:last-child) {
        display: none;
      }

    }

  }
}

/* Sidebar is visible */
@media all and (min-width: 831px) {
  #post-list {
    margin-top: 1.5rem;

    .post-preview .post-meta {
      .pin {
        background: var(--pin-bg);
        border-radius: 5px;
        line-height: 1.4rem;
        height: 1.3rem;
        margin-top: 3px;
        padding-left: 1px;
        padding-right: 6px;

        > span {
          display: inline;
        }
      }
    }
  }

  .pagination {
    font-size: 0.85rem;

    .page-item {
      &:not(:last-child) {
        margin-right: 0.7rem;
      }

      .page-link {
        width: 2rem;
        height: 2rem;
      }

    }

    .page-index {
      display: none;
    }

  } // .pagination

}

/* Pannel hidden */
@media all and (max-width: 1200px) {
  #post-list {
    padding-right: 0;
  }
}
