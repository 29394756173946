@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-extralight-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-extralightitalic-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-heavy-extralightitalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-bold-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-bolditalic-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-ultrabold-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-ultrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Hernandez Niu';
  src: url('../fonts/hernandezniu/hernandezniu-heavy-webfont.woff2') format('woff2'),
  url('../fonts/hernandezniu/hernandezniu-heavy-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
